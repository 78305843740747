<template>
  <div style="width: 100%; ">
      <div style="display: flex;justify-content: center;">
          <div class="title-content">
             <div class="title-img">
                 <img class="title-img" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertconnection/Expertsocialtitle.png">
             </div>
             <div class="title-name">专家社会头衔</div>
          </div>
      </div>
      <div v-for="(it,ind) in expertinfo.titleInfo" :key="ind">
          <div class="info_content">
              <div class="namespace">社会头衔名称：{{it.title}}</div>
              <div v-if="it.images[0] !== null && it.images[0] !== '' || it.images[1] !== null && it.images[1] !== ''" class="namespace">头衔证书（不对外展示）</div>
              <div style="display: flex">
                  <div v-if="it.images[0] !== null && it.images[0] !== ''" style="width: 500px;height: 300px;"><img style="width: 500px;height: 300px;border-radius: 5px;object-fit: cover;" :src="it.images[0]"></div>
                  <div v-if="it.images[1] !== null && it.images[1] !== ''" style="width: 500px;height: 300px;margin-left: 40px;"><img style="width: 500px;height: 300px;border-radius: 5px;object-fit: cover;" :src="it.images[1]"></div>
              </div>
              <div v-if="ind !== expertinfo.titleInfo.length - 1" style="border-bottom: 1px dashed #8AB8B8;padding: 20px 10px;width: 100%;margin: 0 auto"></div>
          </div>
      </div>

      <div style="display: flex;justify-content: center;">
          <div class="title-content">
              <div class="title-img">
                  <img class="title-img" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertconnection/Expertperformane%20andresume.png">
              </div>
              <div class="title-name">专家业绩和履历</div>
          </div>
      </div>
      <div class="info_content">
          <div class="namespace" v-html="expertinfo.content"></div>
      </div>

      <div style="display: flex;justify-content: center;">
          <div class="title-content">
              <div class="title-img">
                  <img class="title-img" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertconnection/Expertmonograph.png">
              </div>
              <div class="title-name">专家专著论文</div>
          </div>
      </div>
      <div v-for="(value,sync) in expertinfo.monograph" :key="sync">
          <div class="info_content">
              <div class="namespace">著作名称：{{value.name}}</div>
              <div style="display: flex;justify-content: space-between">
                  <div class="namespace">出版社：{{value.form}}</div>
                  <div class="namespace">ISBN（国际标准书号）：{{value.ISBN}}</div>
                  <div class="namespace">出版日期：{{value.time}}</div>
              </div>
              <div v-if="value.images[0] !== null && value.images[0] !== '' || value.images[1] !== null && value.images[1] !== ''" class="namespace">著作封面（不对外展示）</div>
              <div style="display: flex">
                  <div  v-if="value.images[0] !== null && value.images[0] !== ''" style="width: 500px;height: 300px;"><img style="width: 500px;height: 300px;border-radius: 5px;object-fit: cover;" :src="value.images[0]"></div>
                  <div  v-if="value.images[1] !== null && value.images[1] !== ''" style="width: 500px;height: 300px;margin-left: 40px;"><img style="width: 500px;height: 300px;border-radius: 5px;object-fit: cover;" :src="value.images[1]"></div>
              </div>
              <div v-if="sync !== expertinfo.monograph.length - 1" style="border-bottom: 1px dashed #8AB8B8;padding: 20px 10px;width: 100%;margin: 0 auto"></div>
          </div>
      </div>
      <div style="border-bottom: 1px dashed #000000;padding: 20px 10px;width: 85%;margin: 0 auto"></div>
      <div v-for="(block,label) in expertinfo.paper" :key="label">
          <div class="info_content">
              <div class="namespace">论文标题：{{block.title}}</div>
              <div style="display: flex;justify-content: space-between">
                  <div class="namespace">发表刊物：{{block.publication}}</div>
                  <div class="namespace">刊号：{{block.publicationNo}}</div>
                  <div class="namespace">出版日期：{{block.time}}</div>
              </div>
              <div v-if="block.images[0] !== null && block.images[0] !== '' || block.images[1] !== null && block.images[1] !== ''" class="namespace">论文封面（不对外展示）</div>
              <div style="display: flex">
                  <div v-if="block.images[0] !== null && block.images[0] !== ''" style="width: 500px;height: 300px;"><img style="width: 500px;height: 300px;border-radius: 5px;object-fit: cover;" :src="block.images[0]"></div>
                  <div v-if="block.images[1] !== null && block.images[1] !== ''"  style="width: 500px;height: 300px;margin-left: 40px;"><img style="width: 500px;height: 300px;border-radius: 5px;object-fit: cover;" :src="block.images[1]"></div>
              </div>
              <div v-if="label !== expertinfo.paper.length - 1" style="border-bottom: 1px dashed #8AB8B8;padding: 20px 10px;width: 100%;margin: 0 auto"></div>
          </div>
      </div>

      <div style="display: flex;justify-content: center;">
          <div class="title-content">
              <div class="title-img">
                  <img class="title-img" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertconnection/law.png">
              </div>
              <div class="title-name">是否有参与制定本专业法律法规、标准规范情况与其他佐证</div>
          </div>
      </div>
      <div class="info_content">
          <div class="namespace">{{expertinfo.other}}</div>
      </div>

<!--    &lt;!&ndash; 其他佐证 &ndash;&gt;-->
<!--      <div style="display: flex;justify-content: center;">-->
<!--          <div class="title-content">-->
<!--              <div class="title-img">-->
<!--                  <img class="title-img" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertconnection/proof.png">-->
<!--              </div>-->
<!--              <div class="title-name"></div>-->
<!--          </div>-->
<!--      </div>-->
<!--      <div class="info_content">-->
<!--          <div class="namespace">宋德萱，教授，博士生导师，从事绿色建筑设计、建筑环境控制、绿建评估策划科研与教学工作，曾多次赴新加坡国立大学建筑学院、香港大学建筑 学系作访问学者，多次在美国、加拿大、日本及欧洲国家考察讲学,并在多项国内外学术会议上作主题报告在绿色与节能建筑设计、绿色评估咨询创 新有丰富的科研积累与实践经验。</div>-->
<!--      </div>-->

<!--  专家话题-->
      <div style="display: flex;justify-content: center;">
          <div class="title-content">
              <div class="title-img">
                  <img class="title-img" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertconnection/topic.png">
              </div>
              <div class="title-name">专家话题</div>
          </div>
      </div>
      <div class="info_content"  v-for="(item,index) in expertinfo.topicList" :key="index">
          <div class="namespace">话题名称：<span style="color: #031F88">{{item.title}}</span></div>
          <div class="namespace">咨询收费标准：<span style="color: #031F88">{{item.money}}</span> 元</div>
          <div class="namespace":style="index !== expertinfo.topicList.length - 1?'':'padding-bottom: 20px'">话题介绍：<span style="color: #031F88">{{item.detail}}</span></div>
          <div v-if="index !== expertinfo.topicList.length - 1" style="border-bottom: 1px dashed #8AB8B8;padding: 20px 10px;width: 100%;margin: 0 auto"></div>
      </div>


  </div>
</template>
<script>

export default {
    components: {

    },
    computed: {

    },
    props: {
        expertinfo:{
            type:Object,
            default(){
                return {

                }
            }
        },
    },
    data() {
        return {

        }
    },

    mounted() {
        // 跳转在顶部
        window.scrollTo(0, 0);
    },

    methods: {

    }
}
</script>
<style scoped>
.title-content {
    width: 1380px;
    height: 60px;
    background: url('https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertconnection/title_content.png');
    margin-top: 40px;
    background-size: cover;
}
.title-img {
    width: 40px;
    height: 40px;
    margin-top: 6px;
    margin-left: 20px;
    float: left;
}
.title-name {
    font-size: 24px;
    font-weight: 600;
    float: left;
    color: #FFFFFF ;
    margin-left: 30px;
    margin-top: 16px;

}
.info_content {
    width: 1300px;
    font-size: 20px;
    margin: 30px auto;
}
.namespace {
    font-weight: 600;
    line-height: 40px;
    white-space: pre-wrap;
    
}

</style>